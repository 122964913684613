import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCookieContext } from '~/utilities/context/dynamic/CookieContext';
import { useStaticContext } from '../static/StaticContext';

const ConsentContext = createContext({
  hasAnalyticsConsent: false,
  hasMarketingConsent: false,
});

export const useConsentContext = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }: { children: React.ReactNode }) => {
  const { cocoCookie, oneTrustCookie } = useCookieContext();
  const {
    configuration: { enableCMP, cmpDomainId, enableCookieWall },
  } = useStaticContext();

  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useState(false);
  const [hasMarketingConsent, setHasMarketingConsent] = useState(false);

  useEffect(() => {
    if (enableCMP) {
      if (!cmpDomainId) {
        console.error('CMP domain ID is missing');
      }

      setHasAnalyticsConsent(
        (oneTrustCookie && !!/C0002:1/.test(oneTrustCookie.toString())) || false
      );
      setHasMarketingConsent(
        (oneTrustCookie && !!/C0004:1/.test(oneTrustCookie.toString())) || false
      );
    } else if (!enableCookieWall) {
      setHasAnalyticsConsent(true);
      setHasMarketingConsent(true);
    } else if (enableCookieWall && cocoCookie) {
      setHasAnalyticsConsent(
        cocoCookie.toString().startsWith('001') || cocoCookie.toString().startsWith('111')
      );
      setHasMarketingConsent(cocoCookie.toString().startsWith('11'));
    }
  }, [
    cocoCookie,
    oneTrustCookie,
    enableCMP,
    cmpDomainId,
    enableCookieWall,
    hasAnalyticsConsent,
    hasMarketingConsent,
  ]);

  const value = useMemo(
    () => ({ hasAnalyticsConsent, hasMarketingConsent }),
    [hasAnalyticsConsent, hasMarketingConsent]
  );

  return <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>;
};
