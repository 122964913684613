import type {
  CartFieldsFragment,
  CartEntryFieldsFragment,
  User,
  Product,
} from '../graphql/codegen';
import { StockLevelMessageKey } from '../graphql/codegen';
import type { VendorEvent } from '../../types/global';
import { getProductSize } from './dataLayer';
import { PageTypes } from '../constants';
import { debounce } from '../../utilities/parsers';

export interface QubitProduct {
  details: {
    additional_image_link: string;
    age_group: string;
    availability: string;
    base_currency: string;
    brand: string;
    categories: string[];
    category: string;
    color: string;
    condition: string;
    currency: string;
    description: string;
    gender: string;
    google_product_category: string;
    gtin: string;
    image_url: string;
    images: string[];
    item_group_id: string;
    language: string;
    locale: string;
    manufacturer: string;
    name: string;
    product_id: string;
    size: string;
    sku_code: string;
    stock: number;
    unit_base_price: string;
    unit_price: number;
    unit_sale_base_price: string;
    unit_sale_price: number;
    url: string;
    views: number;
    views_ip: number;
  };
  id: string;
  strategy: string;
  weight: number;
}

interface QubitEventDetail {
  recsEvent: {
    experienceVersion: string;
    products: QubitProduct[];
    source: string;
  };
}

export interface QubitEvent extends Event {
  readonly detail: QubitEventDetail;
  initCustomEvent(
    typeArg: string,
    canBubbleArg: boolean,
    cancelableArg: boolean,
    detailArg: QubitEventDetail
  ): void;
}

/**
 * Poll for Exponea segments (window.exponea.dimensionsHashed.segments)
 */
export const pollExponeaSegments = () => {
  const POLLING_INTERVAL = 100;
  const POLL_MAX_ATTEMPTS = 30;
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  const executePoll = (resolve: (arg0: never[]) => unknown, reject: unknown) => {
    const exponeaSegments = window?.exponea?.dimensionsHashed?.segments || [];

    attempts++;

    if (exponeaSegments.length) {
      return resolve(exponeaSegments);
    }

    if (attempts >= POLL_MAX_ATTEMPTS) {
      return resolve([]);
    }

    setTimeout(executePoll, POLLING_INTERVAL, resolve, reject);
  };

  return new Promise(executePoll);
};

type QubitEventType =
  | 'ecBasketItem'
  | 'ecBasketSummary'
  | 'ecBasketItemAction'
  | 'ecProduct'
  | 'ecView'
  | 'ecUser'
  | 'ecInteraction';

export const triggerQubitEvent = async (
  type: QubitEventType,
  data: VendorEvent,
  hasConsent: boolean | undefined | null
) => {
  if (!hasConsent) {
    return;
  }

  if (window.uv && data && Object.keys(data).length) {
    let qubitEvent: VendorEvent = {};

    switch (type) {
      case 'ecBasketItem': {
        type CartQubitData = {
          cart: CartFieldsFragment;
          cartEntry: CartEntryFieldsFragment;
        };

        const eventData = data as CartQubitData;
        const { cart, cartEntry } = eventData;

        qubitEvent = {
          basket: {
            id: cart.code,
            [cart.externalTaxEnabled ? 'subtotal' : 'subtotalIncludingTax']: {
              value: cart.externalTaxEnabled
                ? (cart.subTotal?.value || 0) - (cart.totalTax?.value || 0)
                : cart.subTotal?.value,
              currency: cart.subTotal?.currencyIso,
            },
            total: {
              value: cart.totalPrice?.value,
              currency: cart.totalPrice?.currencyIso,
            },
            quantity: cart.totalItems,
          },
          product: {
            productId: cartEntry.product?.baseProduct,
            sku: cartEntry.product?.size?.code || cartEntry.product?.code,
            name: cartEntry.product?.nameEN,
            color: cartEntry.product?.color?.descriptionEN,
            size: cartEntry.product?.size?.code,
            price: {
              currency: cartEntry.product?.price?.currencyIso,
              value: cartEntry.product?.price?.value,
            },
            originalPrice: {
              currency: cartEntry.product?.fromPrice?.currencyIso,
              value: cartEntry.product?.fromPrice?.value,
            },
            categories: [cartEntry.product?.staticCategoryPath?.replace('/', ' > ') ?? ''],
            manufacturer: cartEntry.product?.styleFamilyEN,
          },
          quantity: cartEntry.quantity,
          subtotalIncludingTax: {
            value: cartEntry.totalPrice?.value || 0,
            currency: cartEntry.totalPrice?.currencyIso,
          },
          subtotal: {
            value: cart.externalTaxEnabled
              ? cartEntry.totalPrice?.value || 0
              : (cartEntry.totalPrice?.value || 0) - (cartEntry.taxAmount?.value || 0),
            currency: cartEntry.totalPrice?.currencyIso,
          },
        };
        break;
      }
      case 'ecBasketSummary': {
        const eventData = data as CartFieldsFragment;

        qubitEvent = {
          basket: {
            id: eventData.code,
            [eventData.externalTaxEnabled ? 'subtotal' : 'subtotalIncludingTax']: {
              value: eventData.externalTaxEnabled
                ? (eventData.subTotal?.value || 0) - (eventData.totalTax?.value || 0)
                : eventData.subTotal?.value,
              currency: eventData.subTotal?.currencyIso,
            },
            total: {
              value: eventData.totalPrice?.value,
              currency: eventData.totalPrice?.currencyIso,
            },
            quantity: eventData.totalItems,
          },
        };
        break;
      }
      case 'ecBasketItemAction': {
        type ProductIdType = {
          productId?: string;
          action: 'add' | 'remove';
          quantity: number;
        };

        const eventData = data as ProductIdType;

        qubitEvent = {
          action: eventData.action,
          product: {
            productId: eventData.productId?.toUpperCase(),
          },
          quantity: eventData.quantity,
        };
        break;
      }
      case 'ecProduct': {
        const eventData = data as Product;
        const {
          code,
          nameEN,
          color,
          price,
          fromPrice,
          staticCategoryPath,
          baseProduct,
          stockInformation,
          sizeInformation,
          oneSize,
          isGiftCard,
        } = eventData;
        const isOneSize = oneSize || isGiftCard;

        let sku: string;
        let size: string;
        let stock = 0;

        if (baseProduct) {
          sizeInformation?.sizes?.forEach(size => {
            if (size?.code?.toLowerCase() === String(code).toLowerCase()) {
              stock = size?.purchasable ? 1 : 0;
            }
          });
        } else {
          stock = stockInformation?.stockLevel === StockLevelMessageKey.None ? 0 : 1;
        }

        if (isOneSize) {
          sku = (!baseProduct ? `${code}-PC` : code).toUpperCase();
          size = 'PC';
        } else {
          sku = (baseProduct ? code : '').toUpperCase();
          size = getProductSize(code);
        }

        qubitEvent = {
          eventType: 'detail',
          product: {
            sku,
            productId: (baseProduct ?? code).toUpperCase(),
            name: nameEN,
            stock,
            color: color?.descriptionEN,
            size,
            price: {
              currency: price?.currencyIso,
              value: price?.value,
            },
            originalPrice: {
              currency: fromPrice?.currencyIso,
              value: fromPrice?.value,
            },
            categories: [staticCategoryPath?.replace('/', ' > ') ?? ''],
            manufacturer: 'Manufacturer',
          },
        };
        break;
      }
      case 'ecView': {
        type ViewQubitData = {
          language: string;
          country: string;
          defaultCurrency: string;
          pageType?: PageTypes;
        };

        const eventData = data as ViewQubitData;
        const { language, country, defaultCurrency, pageType } = eventData;

        qubitEvent = {
          type: pageType || PageTypes.OTHER,
          language: `${language}-${country}`,
          country: `${country.toUpperCase()}`,
          currency: defaultCurrency,
        };
        break;
      }
      case 'ecUser': {
        const eventData = data as User;
        const exponeaHashedSegments = await pollExponeaSegments();

        qubitEvent = eventData.hashedUserId
          ? { user: { id: eventData.hashedUserId, exponeaHashedSegments } }
          : { user: { isGuest: true, exponeaHashedSegments } };
        break;
      }
      case 'ecInteraction': {
        type InteractionData = {
          code: string;
        };

        const eventData = data as InteractionData;

        qubitEvent = {
          type: 'quickView',
          name: eventData.code,
        };
        break;
      }
      default:
        break;
    }

    window.uv.emit(`gstar.${type}`, qubitEvent);
  }
};

const sendGaEvent = (
  experienceId: string,
  variationIsControl: boolean,
  label: string,
  url?: string
) => {
  const { dataLayer = [] } = window;

  dataLayer.push({
    event: 'abtest',
    qubit_experience_id: experienceId.split('_')[0],
    ab_test_type: variationIsControl ? 'control' : 'variant',
    ab_test_value: label,
    click_url: url || window.location.href,
  });
};

const customEventHandlers = {
  ctaClickEvent: (
    experienceId: string,
    variationIsControl: boolean,
    element: Element | null | undefined
  ) => {
    if (!element) {
      return;
    }

    const elementType = element.getAttribute('role');

    const ctas = Array.from(
      element.querySelectorAll('a.js-contentBlock-body-link')
    ) as HTMLAnchorElement[];

    ctas.forEach((cta, index) => {
      if (!cta.href || cta.classList.contains(experienceId || '')) {
        return;
      }

      cta.classList.add(experienceId || '');
      cta.addEventListener('click', event => {
        event.preventDefault();
        sendGaEvent(
          experienceId,
          variationIsControl,
          `click_CTA_${index + 1}_${elementType || 'hp_categories'}`,
          cta.href
        );

        window.location.href = cta.href;
      });
    });
  },
  scrollDepthEvent: (experienceId: string, variationIsControl: boolean) => {
    if (document.body.classList.contains('tk37')) {
      return;
    }

    let quarter = false;
    let half = false;
    let threeQuarters = false;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      const totalDocScrollLength = docHeight - windowHeight;
      const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);

      if (scrollPosition >= 25 && scrollPosition < 50) {
        if (!quarter) {
          quarter = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_25');
        }
      } else if (scrollPosition >= 50 && scrollPosition < 75) {
        if (!quarter) {
          quarter = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_25');
        }

        if (!half) {
          half = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_50');
        }
      } else if (scrollPosition >= 75) {
        if (!quarter) {
          quarter = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_25');
        }

        if (!half) {
          half = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_50');
        }

        if (!threeQuarters) {
          threeQuarters = true;
          sendGaEvent(experienceId, variationIsControl, 'scroll_depth_75');
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    document.body.classList.add('tk37');
    window.addEventListener('scroll', debouncedHandleScroll, { passive: true });
  },
};

export const handleCustomQubitEvent = (
  eventName: keyof typeof customEventHandlers,
  experienceId: string,
  variationIsControl: boolean,
  element?: Element | null | undefined
) => {
  if (customEventHandlers[eventName]) {
    customEventHandlers[eventName](experienceId, variationIsControl, element);
  }
};
